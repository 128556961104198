import React from 'react';
import Helmet from 'react-helmet';
import _isEmpty from 'lodash/isEmpty';
import { defineMessages, injectIntl } from 'react-intl';
import { kyruusFormatMessage } from '@kyruus/intl';
import { getProviderImageUrl } from '@kyruus/provider-components';

import {
  getProviderDisplayName,
  isDr,
  specialtyNames,
  sortedLocations,
  showCTA
} from '../provider/utils';
import { messages as schedulingOptionMessages } from '../provider/scheduling-options';

import {
  getProviderProfilePath,
  getProviderProfileUrl
} from 'Common/urls/provider';

const messages = defineMessages({
  doctorName: {
    id: 'provider.profile.description.doctorname',
    description: "A page description for the doctor's name and title",
    defaultMessage: 'Dr. {providerName}'
  },
  basicProviderStatement: {
    id: 'provider.profile.description.statement',
    description: "Page description for the provider's profile",
    defaultMessage:
      'Find information about and book an appointment with {providerName}.'
  },
  basicProviderStatementLocation: {
    id: 'provider.profile.description.statementlocation',
    description: "Page description for the provider's profile with locations",
    defaultMessage:
      'Find information about and book an appointment with {providerName} in {displayLocations}.'
  },
  descriptionLocations: {
    id: 'provider.profile.description.locations',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Practice Locations: {displayLocations}.'
  },
  descriptionSpecialties: {
    id: 'provider.profile.description.specialties',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Specialties: {specialties}.'
  }
});

export function formattedProviderName({ provider, intl }) {
  let providerName = getProviderDisplayName(provider);
  if (isDr(provider)) {
    providerName = kyruusFormatMessage(intl, messages.doctorName, {
      providerName
    });
  }
  return providerName;
}

export function pageTitle({
  /** @string full provider name string */
  providerName,
  /** @Array<string> array of formatted location names */
  locations = [],
  /** @string comma separated list of specialties */
  specialties,
  /** boolean */
  showProviderCta,
  /** injected react-intl object */
  intl
}) {
  const callToAction =
    showProviderCta &&
    `${kyruusFormatMessage(intl, schedulingOptionMessages.ctabutton)}`;

  return [
    providerName,
    locations.length > 0 ? locations[0] : null,
    specialties,
    callToAction
  ]
    .filter(Boolean)
    .join(' - ');
}

function pageDescription(
  /** @string provider full name */
  providerName,
  /** @array array of formatted location names */
  locations,
  /** @string comma separated list of specialties  */
  specialties,
  /** @string professional statement from provider */
  professional_statement,
  /** @object injected intl object from react-intl */
  intl
) {
  const displaySpecialties = specialties
    ? kyruusFormatMessage(intl, messages.descriptionSpecialties, {
        specialties
      })
    : '';
  let displayLocations = [...new Set(locations)].join(', ');

  let description;
  if (professional_statement) {
    displayLocations = _isEmpty(displayLocations)
      ? ''
      : kyruusFormatMessage(intl, messages.descriptionLocations, {
          displayLocations
        });
    description = `${professional_statement} ${displayLocations}`;
  } else {
    const summaryDescriptor = _isEmpty(displayLocations)
      ? messages.basicProviderStatement
      : messages.basicProviderStatementLocation;
    description = kyruusFormatMessage(intl, summaryDescriptor, {
      providerName,
      displayLocations
    });
  }
  return `${description} ${displaySpecialties}`;
}

function ProfileMetas({ intl, profile = {}, config }) {
  const { provider } = profile;
  const providerProfilePath = getProviderProfilePath(provider);
  const providerName = formattedProviderName({
    provider,
    intl
  });
  const locations = (sortedLocations(profile.locations) || []).map((loc) =>
    [loc.city, loc.state].filter(Boolean).join(', ')
  );
  const specialties = (specialtyNames(provider) || []).join(', ');

  const showProviderCta = showCTA(provider, config, true);
  const title = pageTitle({
    providerName,
    locations,
    specialties,
    showProviderCta,
    intl
  });

  // primary domain is set via config, per-customer, and points to the customer's pmc implementation,
  // while 'host' is whatever host the page was requested at
  const host = config.primary_domain || config.host;
  const canonicalUrl = getProviderProfileUrl(host, provider);

  const descriptionMeta = {
    name: 'description',
    content: pageDescription(
      providerName,
      locations,
      specialties,
      provider.professional_statement,
      intl
    )
  };
  const urlMeta = { name: 'url', itemprop: 'url', content: canonicalUrl };
  const ogTitleMeta = { property: 'og:title', content: title };
  const ogImageMeta = {
    property: 'og:image',
    content: getProviderImageUrl(provider)
  };

  const links = config.locale_domains
    ? config.locale_domains.map((localeConfig) => ({
        rel: 'alternate',
        href: `${localeConfig.domain}${providerProfilePath}`,
        hreflang: localeConfig.locale
      }))
    : [];
  links.push({ rel: 'canonical', href: canonicalUrl });

  return (
    <Helmet
      title={title}
      link={links}
      meta={[descriptionMeta, urlMeta, ogTitleMeta, ogImageMeta]}
    />
  );
}

export default injectIntl(ProfileMetas);
