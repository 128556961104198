import querystring from 'querystring';
import _omit from 'lodash/omit';
import _get from 'lodash/get';

import { FROM_SEARCH_MAP } from '../../shared/provider-list';
import {
  VALID_PATIENT_RELS,
  NEW_PATIENT,
  ESTABLISHED_PATIENT
} from '../../utils/constants';
import {
  shouldRenderDirectBookInDrawer,
  MODULES,
  isModuleEnabled
} from 'Common/config';

/** list of query params to omit when building the "back to" links */
const OMIT_QUERY_PARAMS = ['from'];

/**
 * Function that returns the BackToSearchLink's destination from the currentQuery
 * @param {object} currentQuery react-router history's search query
 * @returns {string | {pathname: string, search: string}}}
 */
export const getLinkDestination = ({ currentQuery }) => {
  let linkDestination;
  const { specialty_page, location, specialty, from } = currentQuery;

  if (specialty_page) {
    const nearParam = location ? `/near/${encodeURIComponent(location)}` : '';

    linkDestination = `/specialty/${encodeURIComponent(specialty)}${nearParam}`;
  } else {
    /** '/search' is the default pathname whether or not we are coming `from` FROM_SEARCH_LIST*/
    let pathname = '/search';

    if (from === FROM_SEARCH_MAP) {
      pathname = '/locations';
    }

    linkDestination = {
      pathname,
      search: querystring.stringify(_omit(currentQuery, OMIT_QUERY_PARAMS))
    };
  }

  return linkDestination;
};

export const canSkipModal = (provider, config) => {
  if (shouldRenderDirectBookInDrawer(config)) {
    return true;
  }
  const {
    modal_display: { skip_modal: skipModal, virtual_care: isVirtualCare } = {}
  } = config;
  const virtualCareIsEnabled = Boolean(
    isVirtualCare && provider.virtual_care_url
  );
  // if skipModal is false in config and DB is not rendered in drawer, always return false
  if (!skipModal) {
    return false;
  }

  // if both virtual care is enabled and the provider has a book online url, do not skip the modal
  if (virtualCareIsEnabled && provider.book_online_url) {
    return false;
  }

  // only skip it if they have either one
  return provider.book_online_url || virtualCareIsEnabled;
};

export const isApptInfoValid = (apptInfo) => {
  if (!apptInfo) {
    return false;
  }

  if (!apptInfo.relationship || !apptInfo.purpose) {
    return false;
  }

  if (!VALID_PATIENT_RELS.includes(apptInfo.relationship)) {
    return false;
  }

  if (typeof apptInfo.purpose !== 'string') {
    return false;
  }

  return true;
};

/**
 * Determines if the booking button should be rendered as an anchor tag.
 * It should return false if the customer is configured to use the CTA modal
 * or to render DirectBook in a drawer
 * @param {object} provider provider object
 * @param {object} config customer config
 * @returns {boolean}
 */
export const shouldRenderBookingBtnAsLink = (provider, config) => {
  if (
    canSkipModal(provider, config) &&
    !shouldRenderDirectBookInDrawer(config)
  ) {
    return true;
  }
  return false;
};

/**
 * Determine if the default appointment info should be applied.
 * @param {object} config
 * @param {object} apptInfo e.g. { relationship: "New Problem", purpose: "new"}
 * @param {object} purposes
 * @param {object} profile
 * @returns {boolean}
 */
export const shouldApplyDefaultAppointmentInfo = (
  config,
  apptInfo,
  purposes,
  profile
) => {
  if (!config.display_availability_in_search) {
    return false;
  }
  const apptInfoIsEmpty =
    apptInfo.purpose == null && apptInfo.relationship == null;
  const purposesExist =
    purposes &&
    (Boolean(purposes.new && purposes.new.size) ||
      Boolean(purposes.established && purposes.established.size));
  const providerIsPrimaryCare = _get(
    profile,
    'provider.is_primary_care',
    false
  );
  const agentModeEnabled = isModuleEnabled(config, MODULES.VISIBILITY_AGENT);

  return (
    agentModeEnabled === false &&
    apptInfoIsEmpty &&
    purposesExist &&
    providerIsPrimaryCare
  );
};

/**
 * Return default appointment info for a provider
 * @param {object} purposes e.g. { new: Set, existing: Set }
 * @returns {object} e.g. { relationship: "new", purpose: "new"}
 */
export const getDefaultAppointmentInfo = (purposes) => {
  const newPurposes = [...(purposes.new || [])];
  const establishedPurposes = [...(purposes.established || [])];

  return {
    relationship: newPurposes[0] ? NEW_PATIENT : ESTABLISHED_PATIENT,
    purpose: newPurposes[0] || establishedPurposes[0]
  };
};
