import React, { useState } from 'react';
import { func, object, bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import { kyruusFormatMessage } from '@kyruus/intl';
import { SocialShareMenu as SocialShareComponent } from '@kyruus/social-share';
import Cancel from '@kyruus/react-ikons/Cancel';
import Snackbar, { IconButton as SnackbarIconButton } from '@kyruus/snackbar';

import { IconWrapper } from './styles';
import { specialtyNames } from '../provider/utils';
import { formattedProviderName, pageTitle } from '../profile-v9/profile-metas';
import { getProviderProfileUrl } from 'Common/urls/provider';
import { messages } from '../profile/share-profile';

export const IconWithLabel = ({
  icon,
  label,
  onClick,
  iconPosition = 'right',
  ...props
}) => {
  return (
    <IconWrapper onClick={onClick} iconPosition={iconPosition} {...props}>
      <span>{label}</span>
      <span>{icon}</span>
    </IconWrapper>
  );
};

const SocialShareMenu = ({ profile, config, intl, log }) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const socialShareConfig = config['social_share'] || {};
  const showEmail = !!socialShareConfig.email;
  const showFacebook = !!socialShareConfig.facebook;
  const showTwitter =
    !!socialShareConfig.twitter || !!socialShareConfig.twitterX;

  const provider = profile.provider;
  const providerName = formattedProviderName({ provider, intl });
  const specialties = specialtyNames(provider).join(', ');
  const title = pageTitle({
    providerName,
    specialties,
    intl
  });
  const url = getProviderProfileUrl(
    config.primary_domain || config.host,
    provider
  );

  return (
    <>
      <SocialShareComponent
        url={url}
        text={title}
        showPrint={false}
        showEmail={showEmail}
        showFacebook={showFacebook}
        showTwitterX={showTwitter}
        onProfileLinkClick={async (url) => {
          log('user_action.provider_profile.share_profile_link', {
            provider_id: provider.id
          });
          try {
            await window.navigator.clipboard.writeText(url);
            setShowSnackBar(true);
          } catch (e) {
            log('user_action.provider_profile.share_profile_link.error', {
              provider_id: provider.id,
              error: e.message
            });
          }
        }}
        showLabels={true}
        intl={intl}
      />
      <Snackbar
        message={kyruusFormatMessage(
          intl,
          messages.profileCopiedLinkSnackbarMessage
        )}
        autoHideDuration={3000}
        open={showSnackBar}
        onClose={() => setShowSnackBar(false)}
        action={
          <SnackbarIconButton
            key="close"
            color="inherit"
            onClick={() => setShowSnackBar(false)}
          >
            <Cancel />
          </SnackbarIconButton>
        }
      />
    </>
  );
};

SocialShareMenu.defaultProps = {
  anchorEl: null
};

SocialShareMenu.propTypes = {
  anchorEl: object,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  profile: object.isRequired,
  config: object.isRequired,
  messages: object.isRequired,
  intl: object.isRequired
};

export default injectIntl(SocialShareMenu);
