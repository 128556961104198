import React, { useEffect, useMemo, useState } from 'react';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';
import {
  LoadingAvailabilityTiles,
  VisibleAvailabilityTiles,
  ErrorAvailabilityTiles,
  formatAvailabilityForTiles,
  availabilityTilesTracking
} from '@kyruus/availability-tiles';
import Banner from '@kyruus/banner';

import { appendApptInfoToBookingUrl } from '@kyruus/provider-components';
import ProfileCtaModal from '../profile-cta-modal';
import AvailabilityModal from '../../search/availability-modal';

import {
  PROFILE_MAX_AVAILABILITIES_TO_DISPLAY,
  PROFILE_READ_ONLY_MAX_AVAILABILITIES_TO_DISPLAY,
  SLOTS_LOADING_STATUS,
  SLOTS_ERROR_STATUS
} from '../../utils/constants';
import { pageViewEvent } from '../../tracking/tracking-utils';
import { BOOK_TRACKING_PARAM_NAME, pages } from '../../tracking/constants';
import { getPurposeOptionsByPatientRel } from '../../search/utils';
import {
  getProviderDisplayName,
  getProviderPhoneNumber
} from '../../provider/utils';
import { withQueryParam } from '../../utils/url';
import {
  isApptInfoValid,
  shouldApplyDefaultAppointmentInfo,
  getDefaultAppointmentInfo
} from '../lib/utils';
import { messages as sharedMessages } from '../../shared/messages';
import { messages } from './messages';
import { shouldRenderDirectBookInDrawer } from 'Common/config';
import { getApptPurposeFilterProps } from '../../shared/appt-purpose-filtering';

export const useTracking = ({
  log,
  config,
  providerId,
  profile,
  slotsByProviderId,
  error,
  loading
}) => {
  const hasSlotsInState = slotsByProviderId && slotsByProviderId[providerId];

  useEffect(() => {
    if (hasSlotsInState && config.display_availability_in_search) {
      const { slots = [], apptInfo: { purpose, relationship } = {} } =
        slotsByProviderId[providerId];

      if (slots.length) {
        // profile page only displays avail-tiles if slots are returned (no unavailable view on profile)
        // therefore, only log if slots are returned
        log('user_action.profile.availability_tiles_displayed', {
          provider_id: providerId,
          slots: slots.length,
          purpose,
          relationship
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSlotsInState, config.display_availability_in_search]);

  useEffect(() => {
    /** We log a page view event when all of the following are satisfied: the provider data exists, there is no error, and we are not loading */
    if (profile && !error && !loading) {
      log(pageViewEvent(pages.PROVIDER_PROFILE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, profile, loading]);
};

export const useProfileCtaModal = ({
  profile,
  bookingLinkTarget,
  directBookHref,
  isVirtualCare,
  log,
  config
}) => {
  const [profileCtaModalIsOpen, setProfileCtaModalIsOpen] = useState(false);
  const profileCtaModal = profile ? (
    <ProfileCtaModal
      isOpen={profileCtaModalIsOpen}
      onClose={() => setProfileCtaModalIsOpen(false)}
      provider={profile?.provider}
      bookingLinkTarget={bookingLinkTarget}
      directBookHref={directBookHref}
      isVirtualCare={isVirtualCare}
      log={log}
      config={config}
    />
  ) : null;

  return { profileCtaModal, setProfileCtaModalIsOpen };
};

export const useProfileAvailability = ({
  providerId,
  profile,
  config,
  log,
  directBookHref,
  availabilityLoading,
  slots,
  slotsStatus,
  fetchSlots,
  purposesByProviderId,
  fetchPurposesByProviderId,
  relationship,
  purpose,
  intl,
  history,
  location
}) => {
  const [apptInfo, setApptInfo] = useState({
    relationship,
    purpose
  });
  // store the apptInfo so that we can restore a valid state if the user cancels mid-interaction
  // this is handled inside the `onSelectPurpose` and `closeAvailabilityApptInfoModal` event handlers
  const [lastValidApptInfo, setLastValidApptInfo] = useState(null);

  const [isAvailabilityApptInfoModalOpen, setIsAvailabilityApptInfoModalOpen] =
    useState(false);

  // purposesByProviderId is a { [providerId]: object } object
  // purposes is a { [patientRel]: Set } object
  const purposes = purposesByProviderId
    ? purposesByProviderId[providerId]
    : null;

  useEffect(() => {
    if (purposes == null) {
      // fetch initial slots if they are not already in the store
      if (isApptInfoValid(apptInfo)) {
        fetchSlots(apptInfo, [providerId]);
      }
      // fetch purposes if they are not already in the store
      fetchPurposesByProviderId(providerId);
    }

    // we wont modify apptInfo if it is already set
    if (
      shouldApplyDefaultAppointmentInfo(config, apptInfo, purposes, profile)
    ) {
      const defaultApptInfo = getDefaultAppointmentInfo(purposes);

      if (isApptInfoValid(defaultApptInfo)) {
        fetchSlots(defaultApptInfo, [providerId]);
        setApptInfo(defaultApptInfo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, providerId, purposes]);

  const DEFAULT_PATIENT_RELATIONSHIP_OPTIONS = { new: [], established: [] };
  const patientRelOptions = profile
    ? getPurposeOptionsByPatientRel(profile.provider)
    : DEFAULT_PATIENT_RELATIONSHIP_OPTIONS;

  const {
    direct_book: {
      patient_type: { buttons: availabilityButtonsConfig } = {}
    } = {}
  } = config;

  const isAvailabilityReadOnly = config.enable_read_only_availability;

  const patientRelHasPurposes =
    purposes &&
    apptInfo?.relationship &&
    purposes[apptInfo?.relationship] &&
    purposes[apptInfo?.relationship].size > 0;
  const patientRelHasNoAvailability = (() => {
    if (!availabilityLoading) {
      return !patientRelHasPurposes;
    }

    return false;
  })();
  const purposeHasNoAvailability = (() => {
    if (!availabilityLoading && apptInfo?.relationship && apptInfo?.purpose) {
      return patientRelHasPurposes
        ? !purposes[apptInfo.relationship].has(apptInfo.purpose)
        : true;
    }

    return false;
  })();

  const availabilityByLocationId = useMemo(
    () =>
      (slots || []).reduce((acc, slot) => {
        if (acc[slot.location_id]) {
          return {
            ...acc,
            [slot.location_id]: [...acc[slot.location_id], slot]
          };
        }

        return {
          ...acc,
          [slot.location_id]: [slot]
        };
      }, {}),
    [slots]
  );

  const availabilityTileFactory = (providerLocation) => {
    const isSlotsLoading =
      !profile || availabilityLoading || slotsStatus === SLOTS_LOADING_STATUS;
    const isSlotsError = slotsStatus === SLOTS_ERROR_STATUS;
    const maxAvailabilitiesToDisplay = isAvailabilityReadOnly
      ? PROFILE_READ_ONLY_MAX_AVAILABILITIES_TO_DISPLAY
      : PROFILE_MAX_AVAILABILITIES_TO_DISPLAY;

    const handleTimeSlotsClick = (date, time, provider_id, href) => {
      log('user_action.provider_timeslot_selected_provider_profile', {
        date,
        time,
        provider_id
      });
      const provider = profile.provider;
      if (shouldRenderDirectBookInDrawer(config)) {
        provider &&
          history.push(href, {
            provider: {
              location,
              provider
            }
          });
      }
    };
    const handleViewMoreClick = (_, href) => {
      log(
        'user_action.search_results.profile.availability_tiles.view_all_appointments'
      );
      if (shouldRenderDirectBookInDrawer(config)) {
        const provider = profile.provider;
        provider &&
          history.push(href, {
            provider: {
              location,
              provider
            }
          });
      }
    };

    if (isSlotsLoading) {
      return (
        <LoadingAvailabilityTiles
          maxAvailabilitiesToDisplay={maxAvailabilitiesToDisplay}
          shouldDisplayAsOnMobile={true}
          compactTiles={true}
        />
      );
    }

    if (isSlotsError) {
      return (
        <ErrorAvailabilityTiles
          noMoreRetriesMsg={
            "We're sorry, we're having trouble loading your availability. Please try again later."
          }
          hasMoreRetries={false}
        />
      );
    }

    const hrefWithTracking = withQueryParam(
      directBookHref,
      BOOK_TRACKING_PARAM_NAME,
      availabilityTilesTracking.TRACKING_SOURCE_PROFILE
    );

    if (purposeHasNoAvailability) {
      return (
        <Banner
          icon="!"
          type="warning"
          style={{ lineHeight: 1.42857 }}
          data-testid="NoBookingBanner"
        >
          <KyruusFormattedMessage
            {...sharedMessages.noBookOnlineAvailabilityWarning}
            values={{
              location: providerLocation?.name
            }}
          />
        </Banner>
      );
    }

    return (
      <VisibleAvailabilityTiles
        availabilities={formatAvailabilityForTiles(
          availabilityByLocationId[providerLocation.id],
          hrefWithTracking
        )}
        compactTiles={true}
        providerId={providerId}
        maxAvailabilitiesToDisplay={maxAvailabilitiesToDisplay}
        moreAvailabilitiesLinkText={
          <KyruusFormattedMessage {...messages.moreAvailabilitiesLinkText} />
        }
        isAvailabilityReadOnly={isAvailabilityReadOnly}
        displayLinkToViewMoreAvailabilities={true}
        viewMoreBookOnlineUrl={appendApptInfoToBookingUrl(
          {
            relationship,
            purpose
          },
          hrefWithTracking
        )}
        shouldDisplayAsOnMobile={true}
        onTimeSlotsClick={handleTimeSlotsClick}
        onViewMoreClick={handleViewMoreClick}
        shouldRenderSlotsAsButtons={shouldRenderDirectBookInDrawer(config)}
        locationName={providerLocation.name}
        slotsAriaLabel={(date, time, locationName) =>
          kyruusFormatMessage(
            intl,
            messages.providerAvailabilitySlotsAriaLabel,
            {
              date,
              time,
              locationName
            }
          )
        }
        viewMoreAriaLabel={kyruusFormatMessage(
          intl,
          messages.providerAvailabilityViewMoreAriaLabel,
          {
            providerName: getProviderDisplayName(profile.provider)
          }
        )}
      />
    );
  };

  const editApptInfoCallback = (newApptInfo) => {
    setApptInfo(newApptInfo);
    fetchSlots(newApptInfo, [providerId]);
  };

  const closeAvailabilityApptInfoModal = () => {
    /*
      It is possible the user exits/cancels the modal after selecting a different patient relationship
      and blanking out their appointment purpose without selecting a new appointment purpose.
      In this case, we reset their apptInfo to the last valid state.
    */
    if (apptInfo.purpose === null) {
      setApptInfo(lastValidApptInfo);
    }

    setIsAvailabilityApptInfoModalOpen(false);
  };
  const availabilityApptInfoModal = profile ? (
    <AvailabilityModal
      isOpen={isAvailabilityApptInfoModalOpen}
      patientRel={apptInfo.relationship}
      purpose={{ name: apptInfo.purpose }}
      isPurposeLoading={availabilityLoading}
      onPatientRelSelect={(relationship) =>
        setApptInfo({ relationship, purpose: null })
      }
      onSelectPurpose={(e) => {
        const purpose = e.name;

        setApptInfo((currentApptInfo) => ({
          ...currentApptInfo,
          purpose
        }));
        setLastValidApptInfo({ ...apptInfo, purpose });
      }}
      handleCloseModal={closeAvailabilityApptInfoModal}
      handleConfirmModal={() => {
        fetchSlots(isApptInfoValid(apptInfo) ? apptInfo : lastValidApptInfo, [
          providerId
        ]);
        closeAvailabilityApptInfoModal();
      }}
      patientRelOptions={patientRelOptions}
      providerDisplayName={getProviderDisplayName(profile?.provider)}
      providerPhone={getProviderPhoneNumber(profile?.provider)}
      availabilityButtonsConfig={availabilityButtonsConfig}
      purposeHasNoAvailability={purposeHasNoAvailability}
      patientRelHasNoAvailability={patientRelHasNoAvailability}
      apptPurposeFilterProps={getApptPurposeFilterProps({
        intl,
        config
      })}
    />
  ) : null;

  return {
    apptInfo,
    availabilityByLocationId,
    availabilityTileFactory,
    availabilityApptInfoModal,
    editApptInfoCallback,
    patientRelOptions
  };
};
