import React from 'react';
import { KyruusFormattedMessage } from '@kyruus/intl';

import CtaModal from '../cta-modal';
import { ContactPanel } from '../provider/scheduling-options';

import { getProviderDisplayName } from '../provider/utils';
import { messages } from '../provider/scheduling-options';

const ProfileCtaModal = ({
  isOpen,
  onClose,
  provider,
  bookingLinkTarget,
  directBookHref,
  isVirtualCare,
  log,
  config
}) => {
  let bookOnlinePanel;
  const {
    modal_display: {
      show_book_online_only_with_availability:
        showBookOnlineOnlyWithAvailability
    } = {}
  } = config;
  const bookOnlineUrl = provider?.book_online_url;
  const providerHasAvailability = provider?.has_provider_availability;
  if (bookOnlineUrl) {
    // provider availability will only be considered when config is true
    if (
      !showBookOnlineOnlyWithAvailability ||
      (showBookOnlineOnlyWithAvailability && providerHasAvailability)
    ) {
      bookOnlinePanel = (
        <ContactPanel
          target={bookingLinkTarget}
          contact={directBookHref}
          contactType={'book_online'}
          log={log}
        />
      );
    }
  }

  const providerPhoneContact = provider?.contacts?.find(
    ({ contact_type }) => contact_type === 'phone'
  );

  const phonePanel = providerPhoneContact?.value ? (
    <ContactPanel
      contact={providerPhoneContact.value}
      contactType={'phone'}
      log={log}
    />
  ) : null;

  const requestApptUrl = provider?.request_appointment_url;
  const requestApptPanel = requestApptUrl ? (
    <ContactPanel
      contact={requestApptUrl}
      contactType={'request_form'}
      log={log}
    />
  ) : null;

  const virtualCarePanel =
    isVirtualCare && provider?.virtual_care_url ? (
      <ContactPanel
        contact={provider.virtual_care_url}
        contactType={'virtual_care'}
        log={log}
      />
    ) : null;

  const panels = [
    bookOnlinePanel,
    phonePanel,
    requestApptPanel,
    virtualCarePanel
  ];

  let panelCount = panels.filter(Boolean).length;

  if (
    showBookOnlineOnlyWithAvailability &&
    !providerHasAvailability &&
    panels.every((panel) => !panel)
  ) {
    panels.push(
      <ContactPanel
        contact={null}
        contactType={'no_availability_fallback'}
        log={log}
        handleCloseCtaModal={onClose}
      />
    );
    // design wants the fallback panel to take up the space of 2 normal panels
    // setting panelCount to 2 achieves this requirement
    // https://kyruus.jira.com/browse/KENG-40044 for details
    panelCount = 2;
  }

  return (
    <CtaModal
      id="appt-request"
      title={
        <KyruusFormattedMessage
          {...messages.header}
          values={{
            providerName: getProviderDisplayName(provider)
          }}
        />
      }
      panels={panels}
      open={isOpen}
      onClose={onClose}
      panelCount={panelCount}
    />
  );
};

export default ProfileCtaModal;
