import React from 'react';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import _ from 'lodash';

const messages = defineMessages({
  showmore: {
    id: 'components.showmore',
    description: 'Button text to show more items',
    defaultMessage: 'Show More'
  }
});

class ExpandingDetailList extends React.Component {
  constructor(props) {
    super(props);
    this.showMore = this.showMore.bind(this);
    this.state = {
      numVisible: 0,
      showMoreButton: true
    };
  }

  componentWillMount() {
    if (this.props.data) {
      this.showMore(false);
    }
  }

  showMore(shouldLog = true) {
    let totalLength = this.props.data.length;
    let newLength = this.state.numVisible + this.props.stepSize;
    if (newLength < totalLength) {
      this.setState({
        numVisible: newLength,
        showMoreButton: true
      });
    } else {
      this.setState({
        numVisible: totalLength,
        showMoreButton: false
      });
    }
    if (shouldLog && this.props.log) {
      this.props.log('user_action.alter_view.show_more_detail', {
        detail: this.props.detailName
      });
    }
  }

  render() {
    let detailList = _.map(
      _.take(this.props.data, this.state.numVisible),
      this.props.detailRow
    );
    if (
      this.props.showAllPrintDetails &&
      this.props.data.length > this.state.numVisible
    ) {
      detailList = detailList.concat(
        _.map(
          _.takeRight(
            this.props.data,
            this.props.data.length - this.state.numVisible
          ),
          (detail, index) => {
            return (
              <div
                className="visible-print"
                key={this.state.numVisible + index}
              >
                {this.props.detailRow(detail)}
              </div>
            );
          }
        )
      );
    }
    let details = this.props.detailContainer
      ? this.props.detailContainer(detailList)
      : detailList;

    // TODO make this translatable
    let showMore = this.state.showMoreButton ? (
      <div className="show-more">
        <button
          className="button btn-anchor show-more-button"
          onClick={this.showMore}
        >
          <KyruusFormattedMessage {...messages.showmore} />
          <span className="icon-arrow-down" />
        </button>
      </div>
    ) : null;

    return (
      <div>
        {details}
        {showMore}
      </div>
    );
  }
}

export { ExpandingDetailList }; //eslint-disable-line import/prefer-default-export
