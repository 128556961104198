import React from 'react';
import _ from 'lodash';
import { KyruusFormattedMessage } from '@kyruus/intl';

import { ExpandingDetailList } from './utils';
import { panelTitleMessages } from './nav';

const INITIAL_VISIBLE = 5;

function Training({ data, log }) {
  let trainingsTitle = (training) => {
    return training.name;
  };
  let trainingsSubtitle = (training) => {
    let degreeDescription = _.chain([
      training.type,
      training.field_of_study,
      training.degree,
      training.graduation_year
    ])
      .compact()
      .join(', ')
      .value();
    return <span>{degreeDescription}</span>;
  };

  return (
    <GenericExperience
      data={data}
      title={trainingsTitle}
      subtitle={trainingsSubtitle}
      log={log}
      field="training"
    />
  );
}

function Publications({ data, log }) {
  let publicationsTitle = (pub) => {
    if (pub.source_url) {
      return (
        <a href={pub.source_url} className="publication-url">
          {pub.article_title}
        </a>
      );
    } else {
      return pub.article_title;
    }
  };
  let publicationsSubtitle = (pub) => {
    return _.chain([pub.journal_title, pub.publication_year])
      .compact()
      .join(', ')
      .value();
  };

  return (
    <GenericExperience
      data={data}
      title={publicationsTitle}
      subtitle={publicationsSubtitle}
      log={log}
      field="publications"
    />
  );
}

function BoardCertifications({ data, log }) {
  let certificationsTitle = (cert) => cert.specialty_name;
  let certificationsSubtitle = (cert) => {
    return _.chain([cert.board_name, cert.year_certified])
      .compact()
      .join(', ')
      .value();
  };

  return (
    <GenericExperience
      data={data}
      title={certificationsTitle}
      subtitle={certificationsSubtitle}
      log={log}
      field="board_certifications"
    />
  );
}

function ClinicalTrials({ data, log }) {
  let trialsTitle = (trial) => {
    return trial.brief_title || trial.official_title;
  };
  let trialsSubtitle = (trial) => {
    return trial.authority;
  };

  return (
    <GenericExperience
      data={data}
      title={trialsTitle}
      subtitle={trialsSubtitle}
      log={log}
      field="clinical_trials"
    />
  );
}

function Grants({ data, log }) {
  let grantTitle = (grant) => {
    if (grant.source_url) {
      return (
        <a href={grant.source_url} className="grant-url">
          {grant.project_title}
        </a>
      );
    } else {
      return grant.project_title;
    }
  };
  let grantSubtitle = (grant) => {
    return _.chain([grant.project_start_date, grant.project_end_date])
      .compact()
      .join(' - ')
      .value();
  };

  return (
    <GenericExperience
      data={data}
      title={grantTitle}
      subtitle={grantSubtitle}
      log={log}
      field="grants"
    />
  );
}

function GenericExperience({
  data,
  title = () => {},
  subtitle = () => {},
  log = () => {},
  field
}) {
  if (!_.get(data, 'values.length')) {
    return null;
  }

  let experienceRow = (item, index) => {
    return (
      <div className="row mb-s horizontal-rule-wide" key={index}>
        <div className="col-xs-12">
          <h3 className="fw-4 fs-s mb-s experience-title">{title(item)}</h3>
          <p className="fc-gray-2 experience-subtitle">{subtitle(item)}</p>
        </div>
      </div>
    );
  };
  let experienceList = (experiences) => {
    let experienceTitleMessageDescriptor = {
      id: `field.name.${field}`,
      defaultMessage: _.startCase(field),
      description: 'The title displayed above the experience field'
    };
    return (
      <div id={`experience-${field}`} className="mb-m experience-section">
        <h2 className="experience-field-title">
          <KyruusFormattedMessage {...experienceTitleMessageDescriptor} />
        </h2>
        <div className="mb-m">{experiences}</div>
      </div>
    );
  };

  return (
    <ExpandingDetailList
      data={data.values}
      detailRow={experienceRow}
      detailContainer={experienceList}
      stepSize={INITIAL_VISIBLE}
      log={log}
      detailName={field}
      showAllPrintDetails={true}
    />
  );
}

function Experience({ data, log }) {
  return (
    <div id="provider-details-experience" className="row panel mb-m pt-m pb-s">
      <div className="col-xs-12">
        <div className="row mb-s">
          <div className="col-xs-12">
            <h1 className="fw-6 fs-l">
              <KyruusFormattedMessage {...panelTitleMessages.experience} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Training data={data.training} log={log} />
            <Publications data={data.publications} log={log} />
            <BoardCertifications data={data.board_certifications} log={log} />
            <ClinicalTrials data={data.clinical_trials} log={log} />
            <Grants data={data.grants} log={log} />
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  Training,
  Publications,
  ClinicalTrials,
  Grants,
  BoardCertifications,
  GenericExperience
};
export default Experience;
